import React from 'react';
import { LazyLoad } from 'common/lazyload';

const AboutUsLazy = React.lazy(() => import('./AboutUs'));

const AboutUs: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => {
  return <LazyLoad component={AboutUsLazy} {...props} />;
};

export default AboutUs;