import { Home, Map, ShoppingBag, Icon } from 'react-feather';

export interface NavbarMenuType {
  id: number;
  name: string;
  slug: string;
  icon: Icon;
  link: string;
}

export const navbarMenu: NavbarMenuType[] = [
  {
    id: 1,
    name: 'home',
    slug: 'home',
    icon: Home,
    link: '/',
  },
  {
    id: 2,
    name: 'about us ',
    slug: 'about_us',
    icon: Map,
    link: '/about-us',
  },
  {
    id: 3,
    name: 'products',
    slug: 'products',
    icon: ShoppingBag,
    link: '/category/1',
  },
];

