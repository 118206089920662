import React, { useState } from 'react';
import { useGlobalContextState } from 'context/GlobalProvider';
import { useToggle } from 'hooks';
import WhatsappWidgetModal from 'components/whatsapp-widget-modal';
import TokopediaImg from 'assets/companies/tokopedia.png';
import ShopeeImg from 'assets/companies/shopee.png';
import WhatsappImg from 'assets/companies/whatsapp.png';

import './FixedSosmed.css';

const FixedSosmed: React.FC = () => {
  const { scrollYPage } = useGlobalContextState();

  // State to handle fix navbar
  const [bottom, setBottom] = useState<boolean>(false);

  const { active, toggleActive } = useToggle();

  const handleFix = () => {
    if (window.scrollY >= scrollYPage) {
      return setBottom(true);
    }

    return setBottom(false);
  };

  // Listening scroll
  window.addEventListener('scroll', handleFix);

  return (
    <div className={`fixed__sosmed-container${bottom ? '-bottom' : ''}`}>
      <div className='fixed__sosmed-icon'>
        <a
          href='https://www.tokopedia.com/turana'
          target='_blank'
          rel='noreferrer'
          className='companies__icon'
        >
          <img src={TokopediaImg} alt='Tokopedia Logo' />
        </a>

        <a
          href='https://shopee.co.id/turanaprinting'
          target='_blank'
          rel='noreferrer'
          className='companies__icon'
        >
          <img src={ShopeeImg} alt='Shopee Logo' />
        </a>

        <span onClick={toggleActive} className='companies__icon'>
          <img src={WhatsappImg} alt='Whatsapp Logo' />
        </span>
      </div>
      <WhatsappWidgetModal showModal={active} onClose={toggleActive} />
    </div>
  );
};

export default FixedSosmed;
