import React, { 
  createContext, 
  PropsWithChildren,
  useContext, 
  useEffect, 
  useState, 
} from 'react';

type PageType = 'home' | 'about_us' | 'products';

interface GlobalStateType {
  currentPage: PageType;
  scrollYPage: number;
};

interface GlobalFunctionType {
  setCurrentPage: React.Dispatch<React.SetStateAction<PageType>>;
  setScrollYPage: (y: number) => void;
};

export const GlobalStateContext = createContext<GlobalStateType | undefined>(
  undefined
);

export const GlobalFunctionContext = createContext<GlobalFunctionType | undefined> (
  undefined
);

const GlobalProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [currentPage, setCurrentPage] = useState<PageType>('home');
  const [scrollYPage, setScrollYPage] = useState<number>(1430);

  useEffect(() => {
    if (currentPage === 'products') {
      return setScrollYPage(575);
    }
    return setScrollYPage(1430);
  }, [currentPage, setScrollYPage]);

  return (
    <GlobalStateContext.Provider value={{currentPage, scrollYPage}}>
      <GlobalFunctionContext.Provider value={{setCurrentPage, setScrollYPage}}>
        {children}
      </GlobalFunctionContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalContextState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalContextState must be used within a GlobalProvider');
  }
  return context;
};

export const useGlobalContextFunction = () => {
  const context = useContext(GlobalFunctionContext);
  if (!context) {
    throw new Error('useGlobalContextFunction must be used within a GlobalProvider');
  }
  return context;
};

export default GlobalProvider;
