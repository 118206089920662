import React, { CSSProperties, ErrorInfo, PropsWithChildren } from 'react';
import MaintenanceImage from 'assets/images/maintenance.png';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
    height: '100vh',
  } as CSSProperties,
  welcome: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 10,
    fontWeight: 'bold',
  } as CSSProperties,
  instructions: {
    color: '#225344',
    marginLeft: 24,
    marginRight: 24,
    fontSize: 14,
    textAlign: 'center',
  } as CSSProperties,
  boardicon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 50,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  } as CSSProperties,
  reloadButton: {
    display: 'block',
    width: 280,
    height: 45,
    backgroundColor: '#00ab6b',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    borderRadius: 25,
    border: 0,
  },
  homeButton: {
    display: 'block',
    width: 280,
    height: 45,
    backgroundColor: '#fff',
    border: '1px solid #00ab6b',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    borderRadius: 25,
  },
};

/**
 * Catch all errors from this children component in this single component
 *
 * Remember that this error boundary only catch errors during rendering, in lifecycle methods, and in constructors of the whole tree below them.
 *
 * These are not catched by RootErrorBoundaries:
 * - Event handlers
 * - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 *
 * @see {@link https://reactjs.org/docs/error-boundaries.html}
 */
export default class RootErrorBoundaries extends React.PureComponent<PropsWithChildren> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  /**
   * Handle user's request to go back to home page
   */
  handleGoToHome = () => {
    window.location.assign('/');
  };

  /**
   * Refresh app
   */
  handleRefreshApp = () => {
    window.location.reload();
  };

  render() {
    const { hasError } = this.state;

    const ontitleContent = 'Terjadi kesalahan';
    const onboardContent = 'Silahkan coba beberapa saat lagi';

    if (hasError) {
      return (
        <div style={styles.container}>
          <div style={styles.boardicon}>
            <img
              src={MaintenanceImage}
              alt='Something is wrong'
              style={{ width: 200, height: 175 }}
            />
          </div>

          <div style={styles.welcome}>{ontitleContent}</div>
          <div style={styles.instructions}>{onboardContent}</div>

          <div style={styles.footer}>
            <button onClick={this.handleRefreshApp} style={styles.reloadButton}>
              <span style={{ color: 'white' }}>Coba Lagi</span>
            </button>
            <button onClick={this.handleGoToHome} style={styles.homeButton}>
              <span style={{ color: '#00ab6b' }}>Ke Halaman Utama</span>
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
