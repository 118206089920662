import React from 'react';
import { LazyLoad } from 'common/lazyload';

const ProductPageLazy = React.lazy(() => import('./ProductPage'));

const ProductPage: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => {
  return <LazyLoad component={ProductPageLazy} {...props} />;
};

export default ProductPage;
