import React, { ReactNode, ErrorInfo } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * Error boundary specialized for handling ChunkLoadError, use this
 * to wrap lay loaded components
 * TODO: This error handling is redundant with RootErrorBoundaries. Deprecate this?
 */
class ChunkLoadErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  State
> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   * Chunk or component load errors are usually coming from bad networks.
   * For the solutions, we will ask users to check their networks or retry
   * loading our app.
   */
  componentDidCatch(error: Error, _: ErrorInfo) {
    window.alert('Failed, please refresh the page');
    console.log({ error });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default ChunkLoadErrorBoundary;
