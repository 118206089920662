import React from 'react';
import { LazyLoad } from 'common/lazyload';

const CategoryProductLazy = React.lazy(() => import('./CategoryProduct'));

const CategoryProduct: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => {
  return <LazyLoad component={CategoryProductLazy} {...props} />;
};

export default CategoryProduct;
