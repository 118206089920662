import React, { Suspense } from 'react';
import { Loading } from './components';

interface Props {
  /**
   * Component that need to lazying
   */
  component: React.ComponentType<React.PropsWithChildren<any>>;
}

const LazyLoad: React.FC<React.PropsWithChildren<Props>> = ({ 
  component: Component,
  ...rest
}) => {
  return (
    <Suspense fallback={<Loading />}>
      <Component {...rest} />
    </Suspense>
  );
};

export default LazyLoad;
