import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useGlobalContextState } from 'context/GlobalProvider';
import { useToggle } from 'hooks';
import WhatsappWidgetModal from 'components/whatsapp-widget-modal';
import { navbarMenu, NavbarMenuType } from './constants';
import TuranaLogo from 'assets/logo/turana-logo-full.png';
import FacebookIcon from 'assets/icons/facebook-icon.png';
import LinkedinIcon from 'assets/icons/linkedin-icon.png';
import InstagramIcon from 'assets/icons/instagram-icon.png';
import TiktokIcon from 'assets/icons/tiktok-icon.png';
import TokopediaImg from 'assets/companies/tokopedia.png';
import ShopeeImg from 'assets/companies/shopee.png';
import WhatsappImg from 'assets/companies/whatsapp.png';

import './Navbar.css';

/**
 * Turana navbar component.
 */
const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const { currentPage } = useGlobalContextState();

  const { active, toggleActive } = useToggle();

  const handleNavigate = (nav: NavbarMenuType) => {
    // Do nothing when user in page
    // - Category product
    // - Product page
    if (currentPage === 'products' && nav.slug === 'products') return;

    return navigate(nav.link);
  };

  return (
    <Fragment>
      {/* Navbar for small devices start here */}
      <div className='nav__logo-small'>
        <a href='/'>
          <img src={TuranaLogo} alt='Turana Logo' />
        </a>
        <div className='nav__small-sosmed'>
          <p>Pesan Sekarang</p>
          <div className='nav__sosmed-wrapper'>
            <a
              href='https://www.tokopedia.com/turana'
              target='_blank'
              rel='noreferrer'
            >
              <img src={TokopediaImg} alt='Tokopedia Logo' />
            </a>
            <a
              href='https://shopee.co.id/turanaprinting'
              target='_blank'
              rel='noreferrer'
            >
              <img src={ShopeeImg} alt='Shopee Logo' />
            </a>
            <span onClick={toggleActive}>
              <img src={WhatsappImg} alt='Whatsapp Logo' />
            </span>
          </div>
        </div>
      </div>
      {/* Navbar for small devices end here */}

      <nav className='nav__container'>
        {/* Turana logo for large/medium devices start here */}
        {/* This wrapper will not shown in small devices */}
        <div className='nav__logo-round'>
          <a href='/' className='nav__logo'>
            <img src={TuranaLogo} alt='Turana Logo' />
          </a>
        </div>
        {/* Turana logo for large/medium devices end here */}

        {/* Navbar menu start here */}
        <div className='nav__menu'>
          <div className='nav__menu-left'>
            <p>TURANA MEDIA PROMOSINDO</p>
            <span className='nav__wrapper-icon'>
              <a
                href='https://www.facebook.com/Turana-Media-Promosindo-102514145729558/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={FacebookIcon} alt='Facebook' />
              </a>
              <a href='/'>
                <img src={LinkedinIcon} alt='LinkedIn' />
              </a>
              <a
                href='https://www.instagram.com/turana_printing/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={InstagramIcon} alt='Instagram' />
              </a>
              <a
                href='https://www.tiktok.com/@turana.printing'
                target='_blank'
                rel='noreferrer'
              >
                <img src={TiktokIcon} alt='Tiktok' />
              </a>
            </span>
          </div>
          <ul className='nav__list'>
            {navbarMenu.map((nav, idx) => (
              <li
                key={idx}
                className='nav__item'
                onClick={() => handleNavigate(nav)}
              >
                <span className='nav__icon'>
                  <nav.icon
                    color={
                      currentPage === nav.slug
                        ? 'hsl(237, 62.6%, 27.3%)'
                        : '#949494'
                    }
                    size={22}
                  />
                </span>
                <div
                  className={classNames('nav__link', {
                    selected: nav.slug === currentPage,
                  })}
                >
                  <span>{nav.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {/* Navbar menu end here */}
      </nav>
      <WhatsappWidgetModal 
        showModal={active}
        onClose={toggleActive}
      />
    </Fragment>
  );
};

export default Navbar;
