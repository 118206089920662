import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { X } from 'react-feather';
import useScrollBlock from 'hooks/useScrollBlock';

import './Modal.css';

interface ModalProps {
  /**
   * Control modal visibility
   */
  visible: boolean;

  /**
   * Use animation when opening or closing modal
   */
  animated?: boolean;

  /**
   * Height configuration of the modal
   */
  height?: 'auto' | string;

  /**
   * Duration for slide animation (ms)
   */
  slideDuration?: number;

  /**
   * Callback called when the close button or mask is clicked
   */
  onClose?: Function;

  /**
   * Whether the modal should show dark mask behind
   */
  showMask?: boolean;

  /** Handle modal scrolling
   * In case you need modal to scrolling,
   * Like at modal compareReksadana.
   */
  lockScrolling?: boolean;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  visible,
  children,
  animated = true,
  height = 'auto',
  slideDuration = 300,
  onClose,
  showMask = true,
  lockScrolling = true,
}) => {
  const [modalAnimation, setModalAnimation] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  // tell us where animation is at
  // this state only consider when `animated` props is true
  // 1: the modal not show in the screen. which mean `visible` props is false
  // 2: the modal Pop UP oN the Screen. which mean visible is true. then the MODAL ready to animating close
  const [animationStep, setAnimationStep] = useState<1 | 2>(1);

  // Hook to block/allow scroll
  const [scrollBlock, allowScroll] = useScrollBlock();

  // This will allow devs to opt out of animation if they don't need it
  const transitionStyle = animated
    ? { transition: `${slideDuration}ms ease-out`, height }
    : {};

  /**
   * Handle modal close event
   */
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  // This useEffect block make sure that HTML inside this component will not render
  // on mount, and also let animation finish before unmounting this component
  useEffect(() => {
    let timeout: any;
    // The `visible` variable used in this scope only refers to variable passed
    // from the parent component which use this component. It doesn't reflect
    // `visible` boolean value, which is why `setModalAnimation` inside the negative
    // `visible` block still use normal `visible`, not negated
    if (!visible) {
      // Set animation modal to false, and after animation finish set modal to false
      setModalAnimation(visible);

      // This if block is necessary, because I want the visibility to be set
      // immediately IF the component is not animated. Only wait for setTimeout
      // IF the component is animated
      if (animated && animationStep === 2) {
        timeout = setTimeout(() => {
          // Allow scroll when modal closed
          allowScroll();

          setVisibleModal(visible);

          // reset animation step to be 1 again
          setAnimationStep(1);
        }, slideDuration);
      } else {
        setVisibleModal(visible);
      }
      return;
    }

    setVisibleModal(visible);

    // This if logic works the same as above, only wait for setTimeout if this modal
    // is animated

    // Lock scrolling default true, if true we lock the scrolling.
    if (animated) {
      timeout = setTimeout(() => {
        if (lockScrolling) {
          // Prevent scrolling when modal is open
          scrollBlock();
        }

        // set animating step to be 2
        setAnimationStep(2);
        setModalAnimation(visible);
      }, slideDuration);
    } else {
      setModalAnimation(visible);
    }

    // if this modal (or lets say, page/component that called this modal) is unmounted
    // without triggering 'close' state to this modal, set document.body overflow to normal
    return () => {
      // Allow scroll on un-mounting component
      allowScroll();

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    visible,
    animated,
    slideDuration,
    lockScrolling,
    animationStep,
    scrollBlock,
    allowScroll,
  ]);

  // Don't let this component render HTML unless the modal is ready
  if (!visibleModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={classNames({
        modal: true,
        'modal--visible': modalAnimation,
      })}
    >
      {!!showMask && (
        <div
          className='modal__mask'
          style={transitionStyle}
          onClick={handleClose}
        />
      )}
      <div
        role='dialog'
        aria-modal='true'
        className='modal__body'
        style={transitionStyle}
      >
        <div className='modal__close-btn'>
          <span onClick={handleClose}>
            <X color='#666' size={16} />
          </span>
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
