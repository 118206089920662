import React from 'react';
import { Phone, Smartphone, Mail } from 'react-feather';
import HandImg from 'assets/images/hand-turana.png';
import FacebookIcon from 'assets/icons/facebook-icon.png';
import LinkedinIcon from 'assets/icons/linkedin-icon.png';
import InstagramIcon from 'assets/icons/instagram-icon.png';
import TiktokIcon from 'assets/icons/tiktok-icon.png';

import './Footer.css';

/**
 * Get current year to place on copyright section
 */
const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
  return (
    <div className='footer__container'>
      <div className='footer__text-wrapper'>
        <div className='footer__turana'>
          <h3>TURANA MEDIA PROMOSINDO</h3>
          <p>Jl. H.Risin Jl. Bend. No.74, RT.004/RW.003,</p>
          <p>Cipete, Kec. Pinang, Kota Tangerang, Banten.</p>
          <p>15142</p>
        </div>

        <div className='footer__sosmed'>
          <h3>Follow Us</h3>
          <div className='footer_sosmed-icon'>
            <a
              href='https://www.facebook.com/Turana-Media-Promosindo-102514145729558/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={FacebookIcon} alt='Facebook' />
            </a>
            <a href='/'>
              <img src={LinkedinIcon} alt='LinkedIn' />
            </a>
            <a
              href='https://www.instagram.com/turana_printing/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={InstagramIcon} alt='Instagram' />
            </a>
            <a
              href='https://www.tiktok.com/@turana.printing'
              target='_blank'
              rel='noreferrer'
            >
              <img src={TiktokIcon} alt='Tiktok' />
            </a>
          </div>
        </div>

        <div className='footer__info'>
          <h3>Contact Info</h3>
          <div className='footer__info-wrapper'>
            <span className='footer__icon'>
              <Phone color='#fff' height={16} />
              <p>021 55745595</p>
            </span>
            <span className='footer__icon'>
              <Smartphone color='#fff' height={16} />
              <p>081211960886 / 081212065755</p>
            </span>
            <span className='footer__icon'>
              <Mail color='#fff' height={16} />
              <p>turana.printing@gmail.com</p>
            </span>
          </div>
        </div>
      </div>
      <div className='footer__image'>
        <img src={HandImg} alt='Hand' />
      </div>

      {/* Copyright start here */}
      <div className='copyright'>
        <p>
          Copyright <span className='copyright__icon'>C</span>
          {` ${currentYear} `}
          <b>Turana Media Promosindo</b>, All Rights Reserved.
        </p>
      </div>
      {/* Copyright end here */}
    </div>
  );
};

export default Footer;
