import React from 'react';
import { LazyLoad } from 'common/lazyload';

const HomePageLazy = React.lazy(() => import('./Home'));

const HomePage: React.FC<React.PropsWithChildren<unknown>> = (
  props: any
) => {
  return <LazyLoad component={HomePageLazy} {...props} />;
};

export default HomePage;