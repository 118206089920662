import combineProviders from 'context/combineProviders';
import GlobalProvider from 'context/GlobalProvider';
import RootErrorBoundaries from './RootErrorBoundaries';
import { RouteApp } from 'routes';

// Fonts
import './fonts/MYRIADPRO-REGULAR.OTF';
import './fonts/MYRIADPRO-SEMIBOLD.OTF';

import './App.css';

/** Combine providers */
const Providers = combineProviders([
  GlobalProvider,
]);

/**
 * Representation of Turana website structure in general.
 *
 * Please don't add any complex logic at this component, only new component
 * render representing our website layer
 */
function App() {
  return (
    <RootErrorBoundaries>
      <Providers>
        <RouteApp />
      </Providers>
    </RootErrorBoundaries>
  );
}

export default App;
