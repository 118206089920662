import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from 'pages/home';
import AboutUs from 'pages/about-us';
import CategoryProduct from 'pages/category-product';
import ProductPage from 'pages/product';
import { Navbar } from 'components/navbar';
import { Footer } from 'components/footer';
import { FixedSosmed } from 'components/fixed-sosmed';
import ScrollToTop from 'utils/ScrollToTop';

const RouteApp: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />

      {/* Turana routes start here*/}
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/category/:categoryId' element={<CategoryProduct />} />
        <Route path='/product/:productId' element={<ProductPage />} />
      </Routes>
      {/* Turana routes end here*/}
      
      <FixedSosmed />
      <Footer />
    </Router>
  );
};

export default RouteApp;
