import React, { lazy } from 'react';
import { LazyModal } from 'common/lazymodal';
import { Props } from './WhatsappWidgetModal';

const Modal = lazy(() => import('./WhatsappWidgetModal'));

const WhatsappWidgetModal = (props: Props) => {
  return <LazyModal component={Modal} visible={props.showModal} {...props} />;
};

export default WhatsappWidgetModal;
